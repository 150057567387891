export const assets = {
  glassesMobile0: require('./glasses-mobile-0.jpg'),
  glassesMobile1: require('./glasses-mobile-1.jpg'),
  glassesMobile2: require('./glasses-mobile-2.jpg'),
  glassesPhoto0: require('./glasses-photo-0.jpg'),
  glassesPhoto1: require('./glasses-photo-1.jpg'),
  glassesPhoto2: require('./glasses-photo-2.jpg'),
  hatsMobile0: require('./hats-mobile-0.jpg'),
  hatsMobile1: require('./hats-mobile-1.jpg'),
  hatsMobile2: require('./hats-mobile-2.jpg'),
  hatsPhoto0: require('./hats-photo-0.jpg'),
  hatsPhoto1: require('./hats-photo-1.jpg'),
  hatsPhoto2: require('./hats-photo-2.jpg'),
  jantiesMobile0: require('./janties-mobile-0.jpg'),
  jantiesMobile1: require('./janties-mobile-1.jpg'),
  jantiesMobile2: require('./janties-mobile-2.jpg'),
  jantiesPhoto0: require('./janties-photo-0.jpg'),
  jantiesPhoto1: require('./janties-photo-1.jpg'),
  jantiesPhoto2: require('./janties-photo-2.jpg'),
  masksMobile0: require('./masks-mobile-0.jpg'),
  masksMobile1: require('./masks-mobile-1.jpg'),
  masksMobile2: require('./masks-mobile-2.jpg'),
  masksPhoto0: require('./masks-photo-0.jpg'),
  masksPhoto1: require('./masks-photo-1.jpg'),
  masksPhoto2: require('./masks-photo-2.jpg'),
  storyMobile0: require('./story-mobile-0.jpg'),
  storyPhoto0: require('./story-photo-0.jpg'),
  aboutMobile0: require('./about-mobile-0.jpg'),
  aboutMobile1: require('./about-mobile-1.jpg'),
  aboutMobile2: require('./about-mobile-2.jpg'),
  aboutPhoto0: require('./about-photo-0.jpg'),
  aboutPhoto1: require('./about-photo-1.jpg'),
  aboutPhoto2: require('./about-photo-2.jpg'),
};